<template>
  <div>
    <client-only>
      <HgDealDropdownSearch
        :is-mobile="isMobile"
        :is-initial-search="isInital"
        :search-tags="filteredTags"
        :tags="highlightTags"
        :term="searchTerm"
        @onUpdateSearchTerm="updateSearchTerm"
        @onTriggerSearch="triggerSearch"
      />
    </client-only>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import searchTagsQuery from '../../apollo/queries/deal/searchTags';
import allHighlightTagsQuery from '@/apollo/queries/tags/allHighlightTags';

export default {
  data: () => ({
    isInital: true,
    filteredTags: [],
    searchTerm: '',
    searchTagIds: [],
    searchDealId: '',
    highlightTags: [],
    wordpressMenu: [],
    startingIndex: 0,
    searchTabsComponentKey: 0
  }),
  computed: {
    ...mapState({
      isMobile: (state) => state.isMobile
    })
  },

  watch: {
    '$route.query': 'changeQuery'
  },

  created() {
    const { term } = this.$route.query;
    this.searchTerm = term || '';

    if (this.searchTerm) {
      this.triggerSearch(this.searchTerm);
    }
  },

  methods: {
    updateSearchTerm(term = '') {
      this.searchTerm = term;
      if (
        this.searchTerm &&
        this.searchTerm.length > 1 &&
        !this.checkSearchTerm(term)
      ) {
        this.isInital = false;

        this.debounce(this.queryTags, 300);
      } else this.isInital = true;
    },

    async queryTags() {
      try {
        const {
          data: { searchTags }
        } = await this.$apollo.query({
          query: searchTagsQuery,
          variables: {
            filter: {
              searchTerm: this.searchTerm
            }
          }
        });
        this.filteredTags.pop();
        if (searchTags.length > 0) {
          this.filteredTags.push({
            name: 'searchTags',
            data: searchTags
          });
        } else {
          this.filteredTags.push({
            name: 'searchTags',
            data: [
              {
                name: this.searchTerm
              }
            ]
          });
        }
      } catch (e) {
        // eslint-disable-next-line
        console.warn('error', e);
      } finally {
        this.isLoading = false;
      }
    },

    changeQuery() {
      const { term } = this.$route.query;

      this.searchTerm = term || '';
    },

    checkSearchTerm(searchTerm) {
      const regexExp =
        /^(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})$/gi;
      return regexExp.test(searchTerm);
    },

    checkUuid(searchTerm) {
      // Regular expression to check if string is a valid UUID
      const regexExp =
        /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;
      return regexExp.test(searchTerm);
    },

    debounce(func, delay) {
      clearTimeout(this.timerId);
      this.timerId = setTimeout(func, delay);
    },

    updateRoute() {
      const searchPath = this.localePath('deals');
      const searchTermIsUuid = this.checkUuid(this.searchTerm)
      let tag;

      if (this.filteredTags.length > 0) {
        this.filteredTags.forEach((filteredTag) => {
          if (filteredTag.data.length > 0) {
            filteredTag.data.forEach((data) => {
              if (
                data.id &&
                (data.name.toLowerCase() === this.searchTerm.toLowerCase() ||
                  data.id.toLowerCase() === this.searchTerm.toLowerCase())
              ) {
                tag = data;
              }
            });
          }
        });
      }

      if (tag) {
        // Search query is a tag id; navigate with tagIds= in URL
        this.$store.commit('dealSearch/triggerSearch', {
          searchTerm: null,
          tagIds: [tag.id],
          ids: null
        });

        this.$router.push({
          path: searchPath,
          query: { tagIds: tag.id }
        });
      } else if (searchTermIsUuid) {
        // Search query is a deal id; navigate with ids= in URL
        this.$store.commit('dealSearch/triggerSearch', {
          searchTerm: null,
          tagIds: null,
          ids: [this.searchTerm]
        });

        this.$router.push({
          path: searchPath,
          query: { ids: this.searchTerm }
        });
      } else {
        // Search query is a simple string; navigate with term= in URL
        this.$store.commit('dealSearch/triggerSearch', {
          searchTerm: this.searchTerm,
          tagIds: null,
          ids: null
        });

        this.$router.push({
          path: searchPath,
          query: { term: this.searchTerm }
        });
      }
    },

    async triggerSearch(value) {
      this.searchTerm = value;
      let term = this.searchTerm;

      if (!this.checkSearchTerm(term)) {
        if (this.searchTerm) await this.queryTags();

        // capitalize first letter of each word
        term = term
          .split(' ')
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(' ');

        this.$gtm.push({
          event: 'deal_search_used',
          destination: term
        });

        this.updateRoute();
      }
    }
  },
  apollo: {
    allHighlightTags: {
      prefetch: true,
      errorPolicy: 'all',
      query: allHighlightTagsQuery,
      result({ data }) {
        this.highlightTags = [
          {
            name: 'highlightTags',
            data: data.allHighlightTags.map((highlightTag) => highlightTag.tag)
          }
        ];
      }
    }
  }
};
</script>

<style>
.deal-search .tag-wrapper {
  margin-top: 1rem;
}
</style>
